<template>

    <div class="crud-edit-external-wrapper">
        <div class="admin-view-toolbar " style="margin-bottom: calc(var(--global-margin) * 2);">

            <div class="uk-flex uk-flex-between">
                <div class="start-side">
                    <form-button
                            icon="close"
                            type="secondary"
                            :showSlot="false"
                            class="uk-margin-small-right"
                            @click="redirectBack()"
                            :uk-tooltip="translateTitleCase('core.cancel')"
                    ></form-button>
                    <form-button
                            icon="check"
                            type="primary"
                            :showSlot="false"
                            @click="submit"
                            :uk-tooltip="translateTitleCase('core.crud.saveEdit')"
                    ></form-button></div>
                <div class="end-side"></div>
            </div>
        </div>

        <spinner overlay="fixed" v-if="! isReady"></spinner>
        <base-form
            ref="editForm"
            :action="formAction"
            method="patch"
            v-model="item"
            v-show="isReady"
            :formPath="formPath"
            :config="{context: 'edit'}"
            @form:ready="isReady = true"
            @form:submitSuccess="afterSubmit"
            @form:submitError="handleError"
        ></base-form>

    </div>




</template>

<script>
    import { useStore } from 'vuex'
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import { computed, reactive } from 'vue';
    export default {
        props: {
            apiGetItemUrl : {
                type: String,
                default: false,
            },
            formPath: {
                type: String,
                default: false,
            },
            formAction: {
                type: String,
                default: false,
            },
            successRedirect : {
                type: Object,
                default: {name: 'admin-dashboard'}
            },
            backRoute: {
                type: [Object, Boolean],
                default: false,
            }
        },
        setup (props) {
            let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props);

            return {asyncOps, asyncOpsReady, asyncStatus}
        },
        data: function () {
            return {
                isReady: false,
                item: {},

                asyncData : {
                    item: {
                        target : computed(() => (this.apiGetItemUrl)),
                        data : computed(() => {}),
                        options : {
                            method: 'get',
                        },
                    },
                },
            };
        },
        emits: ['error'],
        computed: {

        },
        methods: {
            redirectBack () {
                if (this.backRoute) {
                    this.$router.push(this.backRoute);
                } else {
                    this.$router.back();
                }

            },
            afterSubmit (result) {
                // show notice, re-route to list
                this.$saffron.ui.notification('<span uk-icon="check"></span>'+this.translateSafe('core.crud.updateSuccess'), 'success');
                this.$router.push(this.successRedirect);
            },
            submit :  async function () {
                this.$refs.editForm.submit();
            },
            handleError({component:form, data, result}) {
               this.$emit('error', {'context': 'submit', data: result})
            }

        },

    }
</script>

<style scoped lang="scss">

</style>
