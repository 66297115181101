<template>
    <layout-admin-dashboard-view
            :showToolbar="false"
            :backRoute="{name: 'admin-config', params: {entityType:entityType}}">
        <template v-slot:title>
            {{translateTitleCase('admin.config.editTitle')}}
        </template>

        <template v-slot:default>

            <crud-edit
                :apiGetItemUrl="'config/entry/'+entryKey"
                :formPath="'config/entry/edit-form/'+entryKey"
                :formAction="'config/entry/'+entryKey"
                :backRoute="{name: 'admin-config'}"
                :successRedirect="{name: 'admin-config'}"
                @error="handleError"
            >

            </crud-edit>

        </template>
    </layout-admin-dashboard-view>
</template>

<script>
    import CrudEdit from '@/client/components/crud/Edit.vue';
    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";

    export default {
        setup (props, setupContext ) {
            let {t} = useI18n();
            let {meta} = useMeta( {
                'title': t('admin.config.editView.title'),
            });

            return {meta}
        },
        props: {
            entryKey: {
                type: String,
                default: false,
            }
        },
        components: {CrudEdit},
        data: function () {
            return {};
        },
        methods: {
            handleError({context, data}) {
                console.log('error', context, data);

            }
        }

    }
</script>

<style scoped lang="scss">

</style>
